<template>
    <div id="order">
        <!-- Form -->
        <section id="form">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="h1 styled-header font-weight-bold mb-5">Оставить заявку</p>
                        </div>
                    </div>

                    <!-- Form -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-10 col-xl-8">
                            <form>
                                <!-- Type -->
                                <div class="row justify-content-center mb-4">
                                    <!--div class="col-12 d-flex">
                                        <p class="h5">Выберите</p>
                                    </div-->

                                    <!-- Measurements -->
                                    <label class="col-12 col-md-6 card-radio-input">
                                        <input type="radio" name="request-type" class="card-input-element d-none" value="web-service" required="">
                                        <div class="card card-form-el card-body border-md style-basic">
                                            <p class="card-title font-header">
                                                Вызов мастера на замеры объекта
                                            </p>
                                            <p class="card-text">
                                                К вам приедет мастер и сделает все нужные замеры.
                                            </p>
                                            <i class="card-icon card-icon-lg fa fa-ruler"></i>
                                        </div>
                                    </label>
                                    <!-- Consultation -->
                                    <label class="col-12 col-md-6 card-radio-input">
                                        <input type="radio" name="request-type" class="card-input-element d-none" value="marketplace">
                                        <div class="card card-form-el card-body border-md style-basic">
                                            <p class="card-title font-header">
                                                Вызов дизайнера для консультации
                                            </p>
                                            <p class="card-text">
                                                К вам приедет дизайнер и проконсультирует вас.
                                            </p>
                                            <i class="card-icon card-icon-lg fa fa-palette"></i>
                                        </div>
                                    </label>
                                    <!-- Call -->
                                    <label class="col-12 col-md-6 card-radio-input">
                                        <input type="radio" name="request-type" class="card-input-element d-none" value="marketplace">
                                        <div class="card card-form-el card-body border-md style-basic">
                                            <p class="card-title font-header">
                                                Звонок для предварительного расчёта
                                            </p>
                                            <p class="card-text">
                                                Вам позвонит или напишет специалист, чтобы рассчитать объем работы.
                                            </p>
                                            <i class="card-icon card-icon-lg fa fa-phone"></i>
                                        </div>
                                    </label>
                                </div>

                                <!-- Name -->
                                <label class="h5">Имя</label>
                                <input name="name" type="text" placeholder="Введите ваше имя" required class="form-control form-control-lg border-md style-basic mb-3">
                                
                                <!-- Contacts -->
                                <label class="h5">Контакты</label>
                                <textarea name="contacts" rows="2" placeholder="Номер телефона/Почта/WhatsApp/Viber/Telegram" required class="form-control form-control-lg border-md style-basic mb-4"></textarea>

                                <!-- Address -->
                                <label class="h5">Адрес</label>
                                <input name="address" type="text" placeholder="Введите ваш адрес" required class="form-control form-control-lg border-md style-basic mb-3">

                                <!-- Design Project -->
                                <label class="h5">Дизайн проект</label>
                                <select name="design-project" class="form-control custom-select form-control-lg border-md style-basic mb-3">
                                    <option value="design">Есть готовый дизайн</option>
                                    <option value="know">Знаем, что хотим сделать</option>
                                    <option value="consult">Нужна консультация</option>
                                </select>
                                
                                <!-- Comments -->
                                <label class="h5">Пожелания</label>
                                <textarea name="comments" rows="5" placeholder="Если у вас есть пожелания, то укажите их" class="form-control form-control-lg border-md style-basic mb-4"></textarea>
                            
                                <div class="d-flex flex-column">
                                    <button type="submit" class="btn btn-md style-active default$ w-100 font-title m-auto">Отправить</button>
                                
                                    <small class="text-center color-basic-text mt-2">
                                        Даю согласие на <a>Обработку персональных данных</a>
                                    </small>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Order'
}
</script>